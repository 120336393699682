.modal-container {
    border: 0;
    padding: 15px 15px 150px 15px;
    position: fixed;
    width: 40vw;
    height: 100%;
    right: 0 !important;
    top: 0 !important;
    z-index: 21000;
    background-color: #fff;
    visibility: visible;
    -webkit-animation: display-none-transition 700ms both !important;
    animation: display-none-transition 700ms both !important;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: -9px 8px 24px -7px rgba(0,0,0,0.32) !important;
    -webkit-box-shadow: -9px 8px 24px -7px rgba(0,0,0,0.32) !important;
    -moz-box-shadow: -9px 8px 24px -7px rgba(0,0,0,0.32) !important;
}
.modal-container-open {
    visibility: hidden;
}
.modal-container-close {
    display: none !important;
}
.modal-content {
    height: 100vh;
    opacity: 1;
    right: 0 !important;
    top: 0 !important;
    position: absolute;
    border: 0 !important;
    padding: 20px 10px 10px 10px;
    display: contents;
}

@-webkit-keyframes display-none-transition
{
    0% { display: none; opacity: 0; }
    1% { display: block; opacity: 0; }
    100% { display: block; opacity: 1; }
}
@keyframes display-none-transition
{
    0% { display: none; opacity: 0; }
    1% { display: block; opacity: 0; }
    100% { display: block; opacity: 1; }
}


.cerrar-modal {
    width: 100%;
    margin-top: 15px !important;
    text-align: right !important;
    position: relative;
    z-index: 1000;
}
.cerrar-modal span {
    font-size: 12px;
}
.cerrar-modal span i {
    font-size: 30px;
}


/*
@media (max-width: 1330px){
    .modal-container {
    width: 100vw !important;}
}
 */

@media (max-width: 992px){
    .modal-container {
        width: 100vw !important;}
    
    .detalle-producto { margin-bottom: 100px;}

}

